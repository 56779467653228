<template>
  <div> 
    <b-row class="m-0  justify-content-center">
      <b-col
        class="content-header"
        cols="9"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1">
          <h3 class="m-0">{{affairForm.name}}</h3>
        </div>
        <div class="content-header-actions d-flex">
            <feather-icon class="cursor-pointer" icon="XIcon" size="24" @click="cancel()" /> 
        </div>
      </b-col>
    </b-row>
    <div v-if="isLoadingAffair" class="content-loader-center m-0 h-100">
      <div class="text-center flex-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        Chargement du formulaire...
      </div>
    </div>
    <b-row v-else class="content-scrollable-sticky m-0 h-100 justify-content-center" >
      <!-- <b-col cols="3" class="content-scrollable-sticky-nav">
        <ul>
          <li><a href="#general" class="current">Général</a></li>
          <li><a href="#juridique">Juridique</a></li>
        </ul>
      </b-col> --> 
      <b-col cols="9" class="content-scrollable-sticky-main">

        <validation-observer ref="formCreateAffair">
          <b-form autocomplete="off" >
            <div id="general" class="my-0">
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1"> 
                  <b-form-group label="Client *" label-for="customer">
                    <validation-provider
                      #default="{ errors }"
                      name="Customer"
                      rules="required"
                    >
                      <v-select
                        id="customer"
                        :loading="isLoadingCompany"
                        :state="errors.length > 0 ? false : null"
                        v-model="affairForm.companyId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="
                          companiesList.map(elem => {
                            return { label: elem.name, value: elem.id }
                          })
                        "
                        :reduce="elem => elem.value"
                      >
                        <template v-slot:no-options>
                          <template> Aucun résultat trouvé </template>
                        </template>

                        <li class="border-bottom p-1 py-50" slot="list-header">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            @click="newCompany()"
                            variant="primary"
                            size="sm"
                            class="w-100 d-flex justify-content-center align-items-center"
                          >
                            + Nouveau client
                            <span class="link_icon material-icons-outlined text-white m-0 ml-50" style="font-size:13px">
                              open_in_new
                            </span>
                          </b-button>
                        </li>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="8" class="py-0 mb-1">
                  <b-form-group label="Nom de l'affaire *" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="affairForm.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Renseigner un nom"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4" class="d-flex py-0 mb-1">
                  <b-form-group label="Code" label-for="code" class="pr-1" style="flex:1">
                    <validation-provider #default="{ errors }" name="Code">
                      <b-form-input
                        id="code"
                        v-model="affairForm.code"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group label="Couleur" label-for="color" class="d-flex flex-column align-content-center align-items-center">
                    <validation-provider #default="{ errors }" name="Color">
                      <v-swatches
                        :state="errors.length > 0 ? false : null" 
                        v-model="affairForm.color"
                        show-fallback
                        popover-x="left"
                        id="color"
                        :show-border="false"
                        :swatch-size="32"
                        :trigger-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                        :swatch-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                        :wrapper-style="{ paddingLeft: '8px', paddingRight: '0px' }"
                      ></v-swatches> 
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="affairForm.ownAddressAffair"
                      class="custom-control-primary"
                    >
                      L'affaire est à une adresse différente de celle du client
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <div v-if="affairForm.ownAddressAffair">
                <b-row class="my-0"> 
                  <b-col cols="12" class="py-0 mb-1">
                    <b-form-group label="Adresse" label-for="address">
                      <validation-provider #default="{ errors }" name="Address">
                          <vue-typeahead-bootstrap
                            type="text"
                            :state="errors.length &gt; 0 ? false : null"
                            class="not-autocomplete" id="address"
                            v-model="affairForm.address"
                            :ieCloseFix="false" 
                            :data="autocompleteAddressData"
                            :serializer="item => item.properties.label"
                            :showAllResults="true"
                            placeholder="Rechercher une adresse ou saisissez : Rue et n° de rue"
                            @input="autocompleteAddressSource()"
                            @hit="autocompleteAddressSelected"
                            autocomplete="nope" aria-autocomplete="nope"
                          >
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                              <div class="item"> 
                                <small>{{data.properties.name}}</small> 
                                <span>{{data.properties.postcode+ ' ' + data.properties.city}}</span> 
                              </div>
                            </template>
                          </vue-typeahead-bootstrap>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="py-0 mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="AddressComplement"
                    >
                      <b-form-input
                        id="addressComplement"
                        v-model="affairForm.addressComplement"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Batiment, Appartement.."
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="3" class="py-0 mb-1">
                    <validation-provider #default="{ errors }" name="ZipCode">
                      <b-form-input
                        id="zipCode"
                        v-model="affairForm.zipCode"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Code postal"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-col>
                  <b-col cols="9" class="py-0 mb-1">
                    <validation-provider #default="{ errors }" name="City">
                      <b-form-input
                        id="city"
                        v-model="affairForm.city"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Ville"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <validation-provider #default="{ errors }" name="Country">
                      <b-form-input
                        id="country"
                        v-model="affairForm.country"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Pays"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row class="my-0">
                  <b-col cols="12" class="py-0">
                    <label for="address'">Coordonnées GPS</label>
                  </b-col>
                  <b-col cols="3" class="py-0 mb-1">
                      <validation-provider name="latitude">
                          <b-form-input class="mb-0" id="latitude" v-model="affairForm.latitude" placeholder="Latitude"></b-form-input>
                      </validation-provider>
                  </b-col>
                  <b-col cols="3" class="py-0 mb-1">
                      <validation-provider name="longitude">
                          <b-form-input class="mb-0" id="longitude" v-model="affairForm.longitude" placeholder="Longitude"></b-form-input>
                      </validation-provider>
                  </b-col>
                </b-row> 

                <vs-divider color="#cccccc" class="mt-0 mb-1"></vs-divider>

              </div>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Description" label-for="description">
                    <validation-provider #default="{ errors }" name="Description">
                      <b-form-textarea
                        id="description"
                        v-model="affairForm.description"
                        :state="errors.length > 0 ? false : null"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Date de début et de fin" label-for="dates">
                    <validation-provider
                      #default="{ errors }"
                      name="Dates"
                    >
                      <date-range-picker
                        ref="picker"
                        id="dates"
                        style="width: 100%"
                        :state="errors.length > 0 ? false : null"
                        @update="setDates"
                        :date-range="dateRange"
                        :show-week-numbers="true"
                        :timePicker="false"
                        :opens="'right'"
                        :append-to-body="true"
                        :locale-data="config"
                        :ranges="defaultRange"
                        v-model="affairDates"
                        :auto-apply="true"
                      >
                      </date-range-picker>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group
                    label="Collaborateurs associés *"
                    label-for="collaborators"
                  >
                    <validation-provider #default="{ errors }" name="Collaborators" 
                      rules="required">
                      <v-select
                        :deselectFromDropdown="true"
                        :closeOnSelect="false"
                        :multiple="true"
                        id="collaborators"
                        :loading="isLoadingCollaboratorsList"
                        :state="errors.length > 0 ? false : null"
                        v-model="affairForm.collaboratorIds"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="
                          collaboratorsList.map(elem => {
                            const name = elem.firstName + ' ' + elem.lastName
                            return { label: name, value: elem.id }
                          })
                        "
                        :reduce="elem => elem.value"
                      >
                        <template v-slot:no-options>
                          <template> Aucun résultat trouvé </template>
                        </template>
                        <li class="border-bottom p-1 py-50" slot="list-header">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            @click="newCollaborator()"
                            variant="primary"
                            size="sm"
                            class="w-100 d-flex justify-content-center align-items-center"
                          >
                            + Nouveau collaborateur
                            <span class="link_icon material-icons-outlined text-white m-0 ml-50" style="font-size:13px">
                              open_in_new
                            </span>
                          </b-button>
                        </li>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>

        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem;"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="affairForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="archiveAffairLocal(affairForm.id, affairForm.name)"
                >
                    <feather-icon icon="ArchiveIcon" /> 
                </b-button>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="create"
              >
                <feather-icon icon="SaveIcon" v-if="affairForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ affairForm.id ? 'Modifier' : 'Ajouter' }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>  


    
  </div>
</template>
<script>
import {debounce} from 'lodash';
import Autocomplete from 'vuejs-auto-complete'
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
var dayjs = require('dayjs')
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var advanced = require('dayjs/plugin/advancedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Paris')
dayjs.extend(advanced)
dayjs.extend(quarterOfYear)
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";


configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

const config = {
  direction: 'ltr',
  format: 'dd/mm/yyyy',
  separator: ' - ',
  applyLabel: 'Appliquer',
  cancelLabel: 'Annuler',
  weekLabel: 'S',
  customRangeLabel: 'Période personnalisée',
  daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  firstDay: 0
}
var defaultRange = {
  'Ce mois-ci': [
    new Date(
      dayjs()
        .startOf('month')
        .hour(12)
    ),
    new Date(dayjs().endOf('month'))
  ],
  'Ce trimestre': [
    new Date(
      dayjs()
        .startOf('quarter')
        .hour(12)
    ),
    new Date(dayjs().endOf('quarter'))
  ],
  'Cette annéee': [
    new Date(
      dayjs()
        .startOf('year')
        .hour(12)
    ),
    new Date(dayjs().endOf('year'))
  ],
  'Année prochaine': [
    new Date(
      dayjs()
        .add(1, 'year')
        .startOf('year')
        .hour(12)
    ),
    new Date(
      dayjs()
        .add(1, 'year')
        .endOf('year')
    )
  ],
  'Année dernière': [
    new Date(
      dayjs()
        .subtract(1, 'year')
        .startOf('year')
        .hour(12)
    ),
    new Date(
      dayjs()
        .subtract(1, 'year')
        .endOf('year')
    )
  ]
}
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      scrolled: false,
      autocompleteAffairName:"", 
      autocompleteAffairNameData:[],
      autocompleteAddressData:[],
      autocompleteAddressIndex:0,
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      required,
      url,
      email,
      displayMore: false,
      dataOrigine:{},
      affairForm:{},
      affairType:{},
      defaultRange: defaultRange,
      config: config,
      dateRange: {
        startDate: null,
        endDate: null
      }
    }
  },
  created(){
    this.popupAffairEvent(this.id)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    ...mapActions([
      "createAffair",
      "fetchAffair",
	    "updateAffair",
      "archiveAffair",
      "fetchCollaboratorsList",
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(".content-scrollable-sticky-nav ul li a");
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach(link => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete(){
      let elements = document.querySelectorAll('.not-autocomplete input');
      if (!elements) {
        return;
      }
      elements.forEach(element => {
        element.setAttribute("autocomplete", "nope");
      });

      elements = document.querySelectorAll('.not-autocomplete');
      if (!elements) {
        return;
      }
      elements.forEach(element => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    popupAffairEvent(id) { 
      this.fetchCollaboratorsList();
      if (id != '') {
        this.fetchAffair(id).then(res => {
          this.affairForm = res
          this.affairForm.latitude = res.latitude ? res.latitude : 1
          this.affairForm.longitude = res.longitude ? res.longitude : 2
          this.affairForm.companyId = res.company.id
          this.affairForm.collaboratorIds = this.affairForm.collaborators.map(
            collaborator => collaborator.id
          )
          delete this.affairForm.collaborators
          this.dataOrigine=JSON.stringify(this.affairForm)
        })
      }else{
        this.initializeForm()
      }
    },
    initializeForm () {
      this.affairForm = {
        id: 0,
        name: '',
        description: '',
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        collaboratorIds: null,
        code: '',
        address: '',
        addressComplement: '',
        zipCode: '',
        city: '',
        country: '',
        ownAddressAffair: false,
        companyId: '',
        latitude: null,
        longitude: null
      }
      this.dataOrigine=JSON.stringify(this.affairForm)
    },
    displayFormComplement(){
      this.displayMore = !this.displayMore
      this.$nextTick(() => {
        this.deteleAutocomplete()
      })
    },
    create (e) {
      e.preventDefault()
      this.$refs.formCreateAffair
        .validate() 
        .then(success1 => {
          if (success1) {
            if (this.affairForm.id) 
              this.updateAffair(this.affairForm)
            else 
              this.createAffair(this.affairForm)
            
            this.$nextTick(() => {
              this.$tabs.close({to : "/affairs"})
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function(indexSource){
      let _this = this
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${this.affairForm.address}&autocomplete=0`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.autocompleteAddressData = data.features;
          console.log("autocompleteAddressSource", this.autocompleteAddressData)
          this.autocompleteAddressIndex = indexSource
        })
    }, 500),
    autocompleteAddressSelected (item) {
      console.log(item)
      this.affairForm.address=item.properties.name
      this.affairForm.city=item.properties.city
      this.affairForm.zipCode=item.properties.postcode 
      this.affairForm.latitude=item.geometry.coordinates[1] 
      this.affairForm.longitude=item.geometry.coordinates[0]      
    },
    setDates (dates) {
      if (dates.startDate != null && dates.endDate != null) {
        this.affairForm.startDate = new Date(dates.startDate).toISOString()
        this.affairForm.endDate = new Date(dates.endDate).toISOString()
      } else {
        this.affairForm.startDate = ''
        this.affairForm.endDate = ''
      }
    },

    // Controls from
    archiveAffairLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveAffair(id);
            this.$tabs.close()
          }
        })
    },
    cancel(){
      if(this.dataOrigine==JSON.stringify(this.affairForm)){
        this.$tabs.close()
      }else{
        this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir quitter l\'édition sans enregistrer ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
           this.$tabs.close()
          }
        })
      }
    },
    newCompany () {
      this.$router.push({ path: '/directory/new-company/clients/5' })
      // if (this.affairForm.id){
      //   this.$router.push({ name: 'new-company', params: { routeOrigine:'edit-affair/'+this.companyForm.id } })
      // }else{
      //   this.$router.push({ name: 'new-company', params: { routeOrigine:'new-affair/' } })
      // }
    },
    newCollaborator () {
      if (this.affairForm.id){
        this.$router.push({ name: 'new-collaborator', params: { routeOrigine:'edit-affair/'+this.affairForm.id } })
      }else{
        this.$router.push({ name: 'new-collaborator', params: { routeOrigine:'new-affair/' } })
      }
    },
  },
  watch: {
    "affairForm.ownAddressAffair": function (newVal, oldVal) {
      if(newVal){
        this.$nextTick(() => {
          this.deteleAutocomplete()
        })
      }
    }
  },
  computed: {
    affairDates: {
      get () {
        return {
          startDate:null,
          endDate:null
        }
      },
      set (value) {
        this.affairForm.startDate = value.startDate
        this.affairForm.endDate = value.endDate
      }
    },
    ...mapGetters([
      "isLoadingUser",
      "usersList",
      "isLoadingCollaboratorsList",
      "isLoadingAffair",
      "collaboratorsList",
      "isLoadingCompany",
      "companiesList"
    ])
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
    VSwatches,
    DateRangePicker
  },
  directives: {
    Ripple,
    mask
  }
}
</script>
<style lang="scss">
.content-scrollable-sticky{
  // display: grid; 
  // grid-template-columns: min-content 1fr; 
  position:relative;
  .content-scrollable-sticky-nav{
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>